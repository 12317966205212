import { useEffect, useState } from 'react';

import { Button, ContentLayout, Grid } from '@cloudscape-design/components';

import { ContactTableMoments } from './ContactTableMoments';
import { GetAIMomentsUseCase, GetAIMomentsUseCaseName } from '../../../domain/use_cases/ai/GetAIMomentsUseCase';
import di from '../../../di/DependencyInjection';
import { Select } from './components/Select';


import "./AiPage.scss";
import Icons from '../../assets/Icons';
import { UpdateAIMomentByIdUseCase, UpdateAIMomentByIdUseCaseName } from '../../../domain/use_cases/ai/UpdateAIMomentByIdUseCase';
import { DeleteAIMomentByIdUseCase, DeleteAIMomentByIdUseCaseName } from '../../../domain/use_cases/ai/DeleteIMomentByIdUseCase';
import { GetAICustomersUseCase, GetAICustomersUseCaseName } from '../../../domain/use_cases/ai/GetAICustomersUseCase';
import { isRight } from 'fp-ts/lib/Either';
import { CreateAIMomentByIdUseCase, CreateAIMomentByIdUseCaseName } from '../../../domain/use_cases/ai/CreateAIMomentByIdUseCase';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';

const typesToShow = [
    'Moment',
    'Question'
];

const weightsM = [
    "Positive",
    "Negative",
    "Neutral"
];
const weightsMKeys: any = {
    "Positive": 1,
    "Negative": -1,
    "Neutral": 0
};

const weightsMKeysFromNumber: any = {
    "1": "Positive",
    "-1": "Negative",
    "0": "Neutral"
};
interface HomePageProps {
}

const AiPage: React.FC<HomePageProps> = () => {

    const [ data, setData ] = useState<any>([]);
    const [ page, setPage ] = useState(1);
    const [ pages, setPages ] = useState(0);
    const [ keywords, setKeywords ] = useState<string[]>([]);
    const [ valueInput, setValueInput ] = useState<string>('');
    const [ modal, setModal ] = useState<any>();
    const [query, setQuery] = useState<any>(
        { 'type': 'Moment', 'customer': '' }
    );
    const [ customers, setCustomers ] = useState<any[]>([]);
    const [ updateDescription, setUpdateDescription ] = useState<string>('');
    const [ updateWeight, setUpdateWeight ] = useState<number>(0);

    const [ isLoading, setIsLoading ] = useState(false);

    const _getCustomers = async () => await di.get<GetAICustomersUseCase>(GetAICustomersUseCaseName)._aiRepository.getAICustomers();

    const firstCall = async (page: number) => {
        setIsLoading(false);
        const responseCustomers = await _getCustomers();
        if (isRight(responseCustomers)) {
            try {
                let result = JSON.parse(responseCustomers.right as string);
                setCustomers(result.data);
                
            } catch (error) {
                setCustomers([]);
            }
            
        }
        const call = async () => await di.get<GetAIMomentsUseCase>(GetAIMomentsUseCaseName)._aiRepository.getAIMoments(keywords, page, query.type, query.customer);
        const response = await call();
        setData([...data, ...response.right.data]);
        setPages(response.right.total_pages);
        setIsLoading(true);
    }

    const _handlePagination = () => {
        if (pages > page) {
            setPage(page + 1); 
            firstCall(page + 1); 
        }
    }

    const filterEmptyKeys = (obj: any) => {
        const shouldKeep = (v: any) => (Array.isArray(v) ? v.length > 0 : v !== null);
        
        return Object.fromEntries(
            Object.entries(obj).filter(([_, v]) => shouldKeep(v))
        );
    };

    const _handleQueryInput = (input: any, field: any) => {
        setQuery((q: any) => filterEmptyKeys({ ...q, [field]: input }));
        let newData = filterEmptyKeys({ ...query, [field]: input });

        const fields = ['customer', 'weight'];

        if (!fields.includes(field)) {
            _handleSearchWithfilters(newData);
        }
        
    }

    const _handleClearMoment = () => {
        setQuery({
            ...query,
            customer: ''
        });
        _handleSearchWithfilters({
            ...query,
            customer: ''
        });
    }

    const _handleKeyword = () => {
        let keys = keywords;
        if (valueInput !== '') {
            keys.push(valueInput);
        }
        setKeywords(keys);
        setValueInput('');
    }

    const  _handleDelteKeyword = (key: string) => {
        let newValues = keywords.filter(item => item !== key);
        setKeywords(newValues);
    }

    const _handleSearchWithfilters = async (queryValue: any) => {
        setIsLoading(false);
        const _call = async () => await di.get<GetAIMomentsUseCase>(GetAIMomentsUseCaseName)._aiRepository.getAIMoments(keywords, page, queryValue.type, queryValue.customer);
        const response = await _call();
        setData([...response.right.data]);
        setPages(response.right.total_pages);
        setPage(1);
        setIsLoading(true);
    }

    const _handleUpdateDescription = (value: string) =>{
        setUpdateDescription(value);
    }

    // Function to format the data and gather elements by unique keys
const formatData = (data: any) => {
    
    // Create a map for autofail and partial arrays
    const autofailMap: any = new Map(data.ids.autofail?.map((item: any) => [item.key, item.value]));
    const partialMap: any = new Map(data.ids.partial?.map((item: any) => [item.key, item.value]));

    // Gather all unique keys from both maps
    const allKeys = new Set([...autofailMap.keys(), ...partialMap.keys()]);

    return Array.from(allKeys).map(key => {
        const partialValue = partialMap.get(key);
        const autofailValue = autofailMap.get(key);
        
        if (data.ids?.allKeys?.autofail[key] === false &&  data?.ids?.allKeys?.partial[key] === false) {
            return {
                key: key,
                partial: 0,
                autofail: 0
            };
        }
        return {
            key: key,
            partial: autofailValue === undefined ? (partialValue === 0 ? 0 : 1) : autofailValue === 1 ? 0 : 1,
            autofail: partialValue === undefined ? (autofailValue === 0 ? 0 : 1) : partialValue === 1 ? 0 : 1
        };
    });
};
    const _handleUpdate = async (value: string) => {
        setIsLoading(false);
        if (value === 'update') {
            if (query.type !== 'Question') {
                query.weight = weightsMKeys[query.weight]
            } 
            
            const call = async () => await di.get<UpdateAIMomentByIdUseCase>(UpdateAIMomentByIdUseCaseName)._aiRepository.UpdateAIMomentById({description: updateDescription, weight: query.weight}, modal.item.keyword_id, undefined);
            await call();
            await _handleSearchWithfilters(query);
            
        }
        if (value === 'delete') {
            if (modal.ids?.active?.length > 0) {
                try {
                    // Map over the ids and call the async function for each id
                    await Promise.all(modal.ids.active.map(async (id: string) => {
                        const call = async () => await di.get<DeleteAIMomentByIdUseCase>(DeleteAIMomentByIdUseCaseName)._aiRepository.DeleteAIMoment(parseInt(id));
                        await call();
                    }));
                    
                    await _handleSearchWithfilters(query);
                
                    setIsLoading(false);
                } catch (error) {
                    setIsLoading(false);
                }
            } 

            let dataFormated = formatData(modal);
            
            if (dataFormated.length > 0) {
                try {
                    // Map over the ids and call the async function for each id
                    await Promise.all(dataFormated.map(async (item: any) => {
                        const call = async () => await di.get<UpdateAIMomentByIdUseCase>(UpdateAIMomentByIdUseCaseName)._aiRepository.UpdateAIMomentById('', parseInt(item.key), {value: item, type: 'partial_autofail'});
                        await call();
                    }));
                    
                    await _handleSearchWithfilters(query);
                
                    setIsLoading(false);
                } catch (error) {
                    setIsLoading(false);
                }
            }
            
            // if (modal.ids?.autofail?.length) {
            //     try {
            //         // Map over the ids and call the async function for each id
            //         await Promise.all(modal.ids.autofail.map(async (item: any) => {
            //             const call = async () => await di.get<UpdateAIMomentByIdUseCase>(UpdateAIMomentByIdUseCaseName)._aiRepository.UpdateAIMomentById('', parseInt(item.key), {value: item.value, type: 'autofail'});
            //             await call();
            //         }));
                    
            //         await _handleSearchWithfilters(query);
                
            //         setIsLoading(false);
            //     } catch (error) {
            //         setIsLoading(false);
            //     }
            // }
            // if (modal.ids?.partial?.length) {
            //     try {
            //         // Map over the ids and call the async function for each id
            //         await Promise.all(modal.ids.partial.map(async (item: any) => {
            //             const call = async () => await di.get<UpdateAIMomentByIdUseCase>(UpdateAIMomentByIdUseCaseName)._aiRepository.UpdateAIMomentById('', parseInt(item.key), {value: item.value, type: 'partial'});
            //             await call();
            //         }));
                    
            //         await _handleSearchWithfilters(query);
                
            //         setIsLoading(false);
            //     } catch (error) {
            //         setIsLoading(false);
            //     }
            // }
        }
        if (value === 'create') {
            const call = async () => await di.get<CreateAIMomentByIdUseCase>(CreateAIMomentByIdUseCaseName)._aiRepository.CreateAIMoment(
                query.customer,
                query.typeCreate,
                query.description,
                weightsMKeys[query.weight]
            );
            await call();
            
            await _handleSearchWithfilters(query);
        }
        setModal(null);
        setIsLoading(true);
    }

    useEffect(() => {
        firstCall(1);
        setQuery({ typeCreate: 'Moment', type: 'Moment' });
    }, []);

    useEffect(() => {
        if (modal?.type === 'update') {
            setQuery({ typeCreate: modal?.item?.type, type: modal?.item?.type });
            setQuery({...query, weight: modal?.item?.type !== 'Question' ? weightsMKeysFromNumber[modal?.item?.weight] : modal?.item?.weight})
        
        
            setUpdateDescription(modal?.item?.description);
        }
    }, [modal]);

    return (
        <div style={{padding: "20px"}}>
            <div className='moment_filter'>  
                    
                    <div className='moment_right_container'>
                        <p style={{marginTop: '10px'}}>Type</p>
                        <Select
                            className="flex-grow-1"
                                options={(typesToShow || []).map((code, i) => ({
                                    label: code,
                                    value: code,
                                }))}
                            onChange={(event: any) =>
                                _handleQueryInput(event.value, "type")
                            }
                            value={
                                query.type
                                    ? { label: query.type, value: query.type }
                                    : null
                                }
                        />
                        <p style={{marginTop: '10px'}}>Customer</p>
                        <Select
                            className="flex-grow-1"
                                options={(customers || []).map((code, i) => ({
                                    label: code,
                                    value: code,
                                }))}
                            onChange={(event: any) =>
                                _handleQueryInput(event.value, "customer")
                            }

                            value={
                                query.customer
                                    ? { label: query.customer, value: query.customer }
                                    : null
                                }
                        />
                        <div
                            className='customer_moment_clear'
                            onClick={() => _handleClearMoment()}
                        >
                                Clear customer
                        </div>
                        <p style={{marginTop: '10px'}}>keyword</p>
                        <div className='input_moment_container'>
                            <input
                                className='input_moment' 
                                type="text"
                                value={valueInput}
                                onChange={(e) => setValueInput(e.target.value)}
                            />
                            <div
                                className='moment_add_keywords'
                                onClick={() => _handleKeyword()}
                            >
                                <Icons.Plus/>
                            </div>
                        </div>
                        <div style={{display: 'flex', marginTop: '10px'}}>
                            {keywords.map(key => {
                                return(
                                    <div style={{display: 'flex', marginRight: '5px', borderRadius: '6px', border: '1px solid black', padding: '2px 5px'}}>
                                        {key}
                                        <div style={{marginLeft: '5px', cursor: 'pointer'}} onClick={() => _handleDelteKeyword(key)}><Icons.Trash /></div>
                                    </div>
                                )
                            })}
                        </div>
                        <br />
                        <Button 
                            variant="primary" onClick={() => {
                                _handleSearchWithfilters(query);
                            }}>
                            Search
                        </Button>
                    </div>
                    <div className='moment_left_container'>
                        <div className='moment_description'>
                            {
                                query.type === "Moment" ?
                                <>
                                    <div style={{marginBottom: '10px'}}>
                                        <strong>Moment</strong>
                                    </div>
                                    <p style={{textAlign: "justify"}}>
                                    Refers to pivotal points or specific situations that occur during the interaction between the agent and the customer, which can significantly influence the direction and outcome of the conversation. These moments can include critical phases such as the greeting, the resolution of the customer's issue, handling objections, or the closing of the call. Each of these moments plays a vital role in shaping the customer's experience and perception of the service, making them essential for evaluating the effectiveness of the agent's communication and problem-solving skills.
                                    </p>
                                </> :
                                <>
                                    <div style={{marginBottom: '10px'}}>
                                        <strong>Question</strong>
                                    </div>
                                    <p style={{textAlign: "justify"}}>
                                    It is a specific item or inquiry included in a structured questionnaire used during quality assessments. These questions are designed to measure distinct aspects of the agent's performance, such as adherence to company protocols, the ability to provide accurate and helpful information, the agent's tone and professionalism, and their overall effectiveness in resolving the customer's issue. Each question targets a particular behavior or outcome and helps the evaluators gauge the agent's competency in delivering high-quality service throughout the call.
                                    </p>
                                </>
                            }
                        </div>
                    </div>
                    
            

            </div>

            {
                isLoading ?
                <>
                <ContentLayout
                        headerBackgroundStyle={"transparent"}
                    >
                        <div style={{marginBottom: "20px"}}></div>
                        <div className='table_moments' >
                        <Grid
                            gridDefinition={[
                                {colspan: { default:12} },
                                {colspan: { default:12} }
                            ]}
                        >
                            <ContactTableMoments
                                data={data as any}
                                tableTitle={query.type}
                                setModal={setModal}
                                modal={modal}
                            />
                            
                        </Grid>
                        </div>
                        
                        
                    </ContentLayout>
                    <br />
                    
                    <div style={{display: 'flex'}}>
                        {
                            pages > 1 && page !== pages &&
                                <Button
                                        variant="normal"
                                        onClick={() => _handlePagination()}
                                    >
                                    Load More
                                </Button>
                        }
                        <div style={{width: '20px'}}></div>
                        <Button
                                variant="primary"
                                onClick={() => {
                                    setModal({type: 'create', item: null})
                                }}
                            >
                            Create
                        </Button>
                        <div style={{width: '20px'}}></div>
                        {
                            (modal?.ids?.active?.length > 0 || modal?.ids?.autofail?.length > 0 || modal?.ids?.partial?.length > 0) &&
                                <Button
                                        variant="primary"
                                        onClick={() => {
                                            _handleUpdate('delete')
                                        }}
                                    >
                                    Save Changes
                                </Button>

                        }
                        
                    </div>
                </> 
                : 
                <div className='loading_moments'>
                    <LoadingComponent />
                </div>
                
            }
            
            {
                modal?.type === 'update' && modal?.item !== undefined &&
                <div className='update_moment'>
                    <div className='update_moment_inside'>
                        <div 
                            onClick={() => {
                                setModal(null);
                            }}
                            className='update_moment_inside_close'
                        >
                            <div style={{cursor: 'pointer'}}>
                                <Icons.CloseX />
                            </div>
                            
                        </div>
                        <p className='update_moment_inside_title'>Update the description</p>
                        <div>
                            <textarea 
                                style={{width: '100%', height: '100px'}}
                                onChange={(e) => _handleUpdateDescription(e.target.value)}
                                value={updateDescription}
                                spellCheck={false}
                            >
                            </textarea>
                        </div>
                        <div>
                        { query.type === 'Moment' ? 'Theme' : 'Score' }
                        {
                            query.type === 'Moment' ?
                            <Select
                            className="flex-grow-1"
                                options={(weightsM || []).map((code, i) => ({
                                    label: code,
                                    value: code,
                                }))}
                            onChange={(event: any) =>
                                _handleQueryInput(event.value, "weight")
                            }

                            value={
                                query.weight !== null
                                    ? { label: query.weight, value: query.weight }
                                    : 0
                            }
                        />
                        :
                        <div>
                            <input
                                type="number"
                                className='moment_input_creation'
                                min="0" // This ensures only numbers greater than 0 are accepted
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    _handleQueryInput(Number(event.target.value), "weight") // Ensures the input is handled as a number
                                }
                                value={query.weight || 0} // Handles the current value, defaulting to an empty string if null
                            />
                        </div>
                        }
                        </div>
                        <br />
                        <Button
                            variant="primary"
                            onClick={() => _handleUpdate('update')}
                        >
                            Update
                        </Button>
                        
                    </div>
                    
                </div>
            }

            {
                modal?.type === 'create' &&
                <div className='update_moment'>
                    <div className='update_moment_inside'>
                        <div 
                            onClick={() => {
                                setModal(null);
                            }}
                            className='update_moment_inside_close'
                        >
                            <div style={{cursor: 'pointer'}}>
                                <Icons.CloseX />
                            </div>
                            
                        </div>
                        <p className='update_moment_inside_title'>Create {query.type}</p>
                        Type
                        <Select
                            className="flex-grow-1"
                                options={(['Moment', 'Question'] || []).map((code, i) => ({
                                    label: code,
                                    value: code,
                                }))}
                            onChange={(event: any) =>
                                _handleQueryInput(event.value, "typeCreate")
                            }

                            value={
                                query.typeCreate
                                    ? { label: query.typeCreate, value: query.typeCreate }
                                    : null
                                }
                        />
                        <br />
                        <div>
                            Description
                            <textarea
                                id="description"
                                style={{ width: '100%', height: '100px', resize: 'vertical' }} // Allows vertical resizing
                                onChange={(e) => _handleQueryInput(e.target.value, "description")} // Updates the 'description' in the query
                                value={query.description || ''} // Binds the value to the query object
                                spellCheck={false} // Disables spell checking
                                placeholder="Enter description here..." // Adds a placeholder for better UX
                            />
                        </div>
                        <br />
                        Customer
                        <Select
                            className="flex-grow-1"
                                options={(customers || []).map((code, i) => ({
                                    label: code,
                                    value: code,
                                }))}
                            onChange={(event: any) =>
                                _handleQueryInput(event.value, "customer")
                            }

                            value={
                                query.customer
                                    ? { label: query.customer, value: query.customer }
                                    : null
                                }
                        />
                        <br />
                        { query.typeCreate === 'Moment' ? 'Theme' : 'Weight' }
                        {
                            query.typeCreate === 'Moment' ?
                            <Select
                            className="flex-grow-1"
                                options={(weightsM || []).map((code, i) => ({
                                    label: code,
                                    value: code,
                                }))}
                            onChange={(event: any) =>
                                _handleQueryInput(event.value, "weight")
                            }

                            value={
                                query.weight !== null
                                    ? { label: query.weight, value: query.weight }
                                    : 0
                            }
                        />
                        :
                        <div>
                            <input
                                type="number"
                                className='moment_input_creation'
                                min="0" // This ensures only numbers greater than 0 are accepted
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    _handleQueryInput(Number(event.target.value), "weight") // Ensures the input is handled as a number
                                }
                                value={query.weight || 0} // Handles the current value, defaulting to an empty string if null
                            />
                        </div>
                        }
                        <br />
                        <Button
                            variant="primary"
                            onClick={() => _handleUpdate('create')}
                        >
                            Create
                        </Button>
                    </div>
                    
                </div>
            }
            {
                modal?.type === 'delete' && false &&
                <div className='update_moment'>
                    <div className='update_moment_inside'>
                        <div 
                            onClick={() => {
                                setModal(null);
                            }}
                            className='update_moment_inside_close'
                        >
                            <div style={{cursor: 'pointer'}}>
                                <Icons.CloseX />
                            </div>
                            
                        </div>
                        <p className='update_moment_inside_title'>Deactivate {query.type}</p>
                        <center>This action will deactivate this {query.type}</center>
                        <br />
                        <Button
                            variant="primary"
                            onClick={() => _handleUpdate('delete')}
                        >
                            Deactivate
                        </Button>
                        
                    </div>
                    
                </div>
            }
            
        </div>
    );
};

export default AiPage;
